
import UI from '../../components/ExcerciseTypes/UseIt';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
   1: { // Exercise num
      unit: 'Unit 2',
      id: 'WB1-U2-P21-E1',
      audio: '',
      video: '',
      component: UI,
      titleImage: '',
      question: [
      ],
      questionImage: [
         [
            { url: 'img/FriendsPlus/Page19/2.jpg' },
         ],
      ]
   },
   2: { // Exercise num
      unit: 'Unit 2',
      id: 'WB1-U2-P21-E2',
      audio: '/img/FriendsPlus/Page19/Audio/audio.e2.mp3',
      video: '',
      component: D1,
      typeInput: 'center',
      fontSize: 40,
      inputHeight: '100%',
      isAllowSubmit: false,
      exerciseKey: 'img/FriendsPlus/Page19/E2/Key/answerKey.png',
      questionImage: [ // Row
         [
            // Column1
            { url: 'img/FriendsPlus/Page19/E2/1.jpg' },
            { url: 'img/FriendsPlus/Page19/E2/2.jpg' },
            { url: 'img/FriendsPlus/Page19/E2/3.jpg' },
         ],
         [
            // 
            { url: 'img/FriendsPlus/Page19/E2/4.jpg' },
         ],
         [
            // Column2
            { url: 'img/FriendsPlus/Page19/E2/5.jpg' },
            { url: 'img/FriendsPlus/Page19/E2/6.jpg', input: true, answer: "6" },
            { url: 'img/FriendsPlus/Page19/E2/7.jpg' },
            { url: 'img/FriendsPlus/Page19/E2/8.jpg', input: true, answer: "5" },
            { url: 'img/FriendsPlus/Page19/E2/9.jpg' },
            { url: 'img/FriendsPlus/Page19/E2/10.jpg', input: true, answer: "3" },
            { url: 'img/FriendsPlus/Page19/E2/11.jpg' },
         ],
         [
            // Column3
            { url: 'img/FriendsPlus/Page19/E2/12.jpg' },
         ],

      ],
   },
   3: { // Exercise num
      unit: 'Unit 2',
      id: 'WB1-U2-P21-E3',
      audio: '',
      video: '',
      component: DesignUnderLine,
      totalInput: 2,
      exerciseKey: 'img/FriendsPlus/Page19/E3/Key/answerKey.png',
      titleImage: '',
      questionImage: [ // Row
         [
            // Column1
            { url: 'img/FriendsPlus/Page19/E3/1.jpg' },
         ],
         [
            // Column2
            { url: 'img/FriendsPlus/Page19/E3/2.jpg', input: 1 },
            { url: 'img/FriendsPlus/Page19/E3/3.jpg', input: 2 },
            { url: 'img/FriendsPlus/Page19/E3/4.jpg', input: 3 },
            { url: 'img/FriendsPlus/Page19/E3/5.jpg', input: 4 },
            { url: 'img/FriendsPlus/Page19/E3/6.jpg', input: 5, isCorrect: true },
            { url: 'img/FriendsPlus/Page19/E3/7.jpg', input: 6, isCorrect: true },
            { url: 'img/FriendsPlus/Page19/E3/8.jpg', input: 7 },
            { url: 'img/FriendsPlus/Page19/E3/9.jpg', input: 8 },
            { url: 'img/FriendsPlus/Page19/E3/10.jpg', input: 9 },
            { url: 'img/FriendsPlus/Page19/E3/11.jpg', input: 10 },
         ],

      ],
      questions: [
         {
            title: 'The dictionaries are # the shelf',
            answer: ['table']
         },
      ]
   },

}

export default json;