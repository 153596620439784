import LT2 from '../../components/ExcerciseTypes/LineTo/LT2';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB1-U5-P40-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    // component: DesignUnderLine1,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page40/E1/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page40/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page40/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/7.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page40/E1/8.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page40/E1/9.jpg', input: 1 },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page40/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/11.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page40/E1/12.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page40/E1/13.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page40/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/15.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page40/E1/16.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page40/E1/17.jpg', input: 3 },
      ],


    ],
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'WB1-U5-P40-E2',
    audio: '',
    video: '',
    component: D1,
    typeInput: 'center',
    fontSize: 30,
    inputHeight: 33,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page40/E2/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page40/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page40/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page40/E2/3.jpg', input: true, answer: "f" },
        { url: 'img/FriendsPlus/Page40/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page40/E2/5.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page40/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page40/E2/7.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page40/E2/8.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page40/E2/9.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 5',
    id: 'WB1-U5-P40-E3',
    // audio: 'img/FriendsPlus/Page40/E3/Audio/sing.e2.p11.mp3',
    video: '',
    component: LT2,
    exerciseKey: 'img/FriendsPlus/Page40/E3/Key/answerKey.png',
    recorder: true,
    toAnchor: '50% -1%',
    fromAnchor: '50% 100%',
    // titleImage: 'img/FriendsPlus/Page40/E3/1.jpg',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page40/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page40/E3/2.jpg', isMatching: true, id: 1 },
        { url: 'img/FriendsPlus/Page40/E3/3.jpg', isMatching: true, id: 2 },
        { url: 'img/FriendsPlus/Page40/E3/4.jpg', isMatching: true, id: 3 },
        { url: 'img/FriendsPlus/Page40/E3/5.jpg', isMatching: true, id: 4 },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page40/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page40/E3/7.jpg' },
        { url: 'img/FriendsPlus/Page40/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page40/E3/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page40/E3/10.jpg', isMatching: true, id: 5 },
        { url: 'img/FriendsPlus/Page40/E3/11.jpg', isMatching: true, id: 6 },
        { url: 'img/FriendsPlus/Page40/E3/12.jpg', isMatching: true, id: 7 },
        { url: 'img/FriendsPlus/Page40/E3/13.jpg', isMatching: true, id: 8 },
      ],

    ],
    answers: ['1-7', '2-8', '3-5', '4-6'],
  },



}

export default json;