import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';




const json = {
    1: { // Exercise num
        unit: 'Unit 6',
        id: 'WB1-U6-P46-E1',
        audio: '',
        video: '',
        component: DesignUnderLine,
        // component: DesignUnderLine1,
        totalInput: 6,
        exerciseKey: 'img/FriendsPlus/Page46/E1/Key/answerKey.png',
        titleImage: '',
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page46/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page46/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page46/E1/3.jpg', input: 1 },
                { url: 'img/FriendsPlus/Page46/E1/4.jpg', input: 1, isCorrect: true },
                { url: 'img/FriendsPlus/Page46/E1/5.jpg' },
                { url: 'img/FriendsPlus/Page46/E1/6.jpg', input: 2, isCorrect: true },
                { url: 'img/FriendsPlus/Page46/E1/7.jpg', input: 2 },
                { url: 'img/FriendsPlus/Page46/E1/8.jpg' },
                { url: 'img/FriendsPlus/Page46/E1/9.jpg', input: 3, isCorrect: true },
                { url: 'img/FriendsPlus/Page46/E1/10.jpg', input: 3 },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page46/E1/11.jpg' },
                { url: 'img/FriendsPlus/Page46/E1/12.jpg', input: 4 },
                { url: 'img/FriendsPlus/Page46/E1/13.jpg', input: 4, isCorrect: true },
                { url: 'img/FriendsPlus/Page46/E1/14.jpg' },
                { url: 'img/FriendsPlus/Page46/E1/15.jpg', input: 5 },
                { url: 'img/FriendsPlus/Page46/E1/16.jpg', input: 5, isCorrect: true },
                { url: 'img/FriendsPlus/Page46/E1/17.jpg' },
                { url: 'img/FriendsPlus/Page46/E1/18.jpg', input: 6, isCorrect: true },
                { url: 'img/FriendsPlus/Page46/E1/19.jpg', input: 6 },
            ],


        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    2: { // Exercise num
        unit: 'Unit 6',
        id: 'WB1-U6-P46-E2',
        audio: '',
        video: '',
        component: D1,
        typeInput: 'center',
        fontSize: 42,
        inputHeight: 45,
        isAllowSubmit: false,
        exerciseKey: 'img/FriendsPlus/Page46/E2/Key/answerKey.png',
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page46/E2/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page46/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page46/E2/3.jpg', input: true, answer: "k" },
                { url: 'img/FriendsPlus/Page46/E2/4.jpg' },
                { url: 'img/FriendsPlus/Page46/E2/5.jpg', input: true, answer: "l" },
                { url: 'img/FriendsPlus/Page46/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page46/E2/7.jpg', input: true, answer: "g" },
                { url: 'img/FriendsPlus/Page46/E2/8.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page46/E2/9.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page46/E2/10.jpg' },
                { url: 'img/FriendsPlus/Page46/E2/11.jpg', input: true, answer: "h" },
                { url: 'img/FriendsPlus/Page46/E2/12.jpg' },
                { url: 'img/FriendsPlus/Page46/E2/13.jpg', input: true, answer: "d" },
                { url: 'img/FriendsPlus/Page46/E2/14.jpg' },
                { url: 'img/FriendsPlus/Page46/E2/15.jpg', input: true, answer: "k" },
                { url: 'img/FriendsPlus/Page46/E2/16.jpg' },
                { url: 'img/FriendsPlus/Page46/E2/17.jpg', input: true, answer: "c" },
                { url: 'img/FriendsPlus/Page46/E2/18.jpg' },
            ],

        ],
    },

    3: { // Exercise num
        unit: 'Unit 6',
        id: 'WB1-U6-P46-E3',
        audio: '',
        video: '',
        component: D1,
        typeInput: 'center',
        fontSize: 40,
        inputHeight: 42,
        isAllowSubmit: false,
        exerciseKey: 'img/FriendsPlus/Page46/E3/Key/answerKey.png',
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page46/E3/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page46/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page46/E3/3.jpg', input: true, answer: "d" },
                { url: 'img/FriendsPlus/Page46/E3/4.jpg' },
                { url: 'img/FriendsPlus/Page46/E3/5.jpg', input: true, answer: "f" },
                { url: 'img/FriendsPlus/Page46/E3/6.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page46/E3/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page46/E3/8.jpg' },
                { url: 'img/FriendsPlus/Page46/E3/9.jpg', input: true, answer: "g" },
                { url: 'img/FriendsPlus/Page46/E3/10.jpg' },
                { url: 'img/FriendsPlus/Page46/E3/11.jpg', input: true, answer: "a" },
                { url: 'img/FriendsPlus/Page46/E3/12.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page46/E3/13.jpg' },
            ],

        ],
    },
}

export default json;