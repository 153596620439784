import LT1 from '../../components/ExcerciseTypes/LineTo/LT1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB1-U5-P39-E1',
    audio: '',
    video: '',
    component: D1,
    typeInput: 'center',
    fontSize: 40,
    inputHeight: '100%',
    isAllowSubmit: true,
    exerciseKey: 'img/FriendsPlus/Page39/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page39/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page39/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page39/E1/3.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page39/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page39/E1/5.jpg', input: true, answer: "1" },
        { url: 'img/FriendsPlus/Page39/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page39/E1/7.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page39/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page39/E1/9.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page39/E1/10.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page39/E1/11.jpg' },
      ],


    ],
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'WB1-U5-P39-E2',
    //audio: 'img/FriendsPlus/Page11/E2/Audio/sing.e2.p11.mp3',
    video: '',
    component: LT1,
    exerciseKey: 'img/FriendsPlus/Page39/E2/Key/answerKey.png',
    recorder: true,
    fromAnchor: '100% 50%',
    toAnchor: '0% 50%',
    titleImage: 'img/FriendsPlus/Page39/E2/1.jpg',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page39/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page39/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page39/E2/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page39/E2/3.jpg', isMatching: true, id: 1 },
        { url: 'img/FriendsPlus/Page39/E2/7.jpg', isMatching: true, id: 2 },
        { url: 'img/FriendsPlus/Page39/E2/11.jpg', isMatching: true, id: 3 },
      ],
      [
        { url: 'img/FriendsPlus/Page39/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page39/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page39/E2/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page39/E2/5.jpg', isMatching: true, id: 4 },
        { url: 'img/FriendsPlus/Page39/E2/9.jpg', isMatching: true, id: 5 },
        { url: 'img/FriendsPlus/Page39/E2/13.jpg', isMatching: true, id: 6 },
      ],
    ],
    answers: ['1-6', '2-4', '3-5'],
  },
  3: { // Exercise num
    unit: 'Unit 5',
    id: 'WB1-U5-P39-E3',
    audio: '',
    video: '',
    component: D1,
    recorder: false,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page39/E3/1.jpg' },
      ],



    ],
  },



}

export default json;