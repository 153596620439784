
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';



const json = {

  1: { // Exercise num
    unit: 'Culture 2',
    id: 'WB1-C-P53-E1',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: true,
    fontSize: 30,
    inputHeight: '100%',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page53/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E1/5.jpg' },
      ],
    ]
  },

  2: { // Exercise num
    unit: 'Culture 2',
    id: 'WB1-C-P53-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page53/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E2/5.jpg' },
      ],
    ]
  },
}
export default json;