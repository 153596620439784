import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';

const json = {

    1: { // Exercise num
        unit: 'Unit 6',
        id: 'WB1-U6-P48-E1',
        audio: '',
        video: '',
        component: D1,
        //recorder: true,
        isAllowSubmit: false,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page48/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page48/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page48/Audio/nose.mp3' },
                { url: 'img/FriendsPlus/Page48/E1/3.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page48/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page48/Audio/drink.mp3' },
                { url: 'img/FriendsPlus/Page48/E1/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page48/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page48/Audio/lunchbox.mp3' },
                { url: 'img/FriendsPlus/Page48/E1/7.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page48/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page48/Audio/tiger.mp3' },
                { url: 'img/FriendsPlus/Page48/E1/9.jpg' },
            ],

        ],
    },
    2: { // Exercise num
        unit: 'Unit 6',
        id: 'WB1-U6-P48-E2',
        audio: '',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page48/E2/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page48/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page48/Audio/This is my face..mp3' },
                { url: 'img/FriendsPlus/Page48/E2/3.jpg', audioUrl: 'img/FriendsPlus/Page48/Audio/I have a sandwich..mp3' },
                { url: 'img/FriendsPlus/Page48/E2/4.jpg', audioUrl: "img/FriendsPlus/Page48/Audio/They're bears..mp3" },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page48/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page48/Audio/I have a pear..mp3' },
                { url: 'img/FriendsPlus/Page48/E2/6.jpg', audioUrl: 'img/FriendsPlus/Page48/Audio/I have a cookie..mp3' },
                { url: 'img/FriendsPlus/Page48/E2/7.jpg', audioUrl: "img/FriendsPlus/Page48/Audio/They're birds..mp3" },
            ],

        ],
    },


}

export default json;