
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {

  2: { // Exercise num
    unit: 'Culture',
    id: 'WB1-C-P51-E2',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: true,
    fontSize: 30,
    inputHeight: '100%',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page51/E2/3.jpg' },
      ],
    ]
  },
}
export default json;