import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/UnderLine";

const json = {
  3: {
    // Exercise num
    unit: "Unit 6",
    id: "WB1-U6-P49-E3",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 4,
    exerciseKey: "img/FriendsPlus/Page49/E3/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page49/E3/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page49/E3/2.jpg" },
        { url: "img/FriendsPlus/Page49/E3/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page49/E3/4.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page49/E3/5.jpg", input: 1 },
        { url: "img/FriendsPlus/Page49/E3/6.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page49/E3/7.jpg" },
        { url: "img/FriendsPlus/Page49/E3/8.jpg", input: 2 },
        { url: "img/FriendsPlus/Page49/E3/9.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page49/E3/10.jpg", input: 2 },
        { url: "img/FriendsPlus/Page49/E3/11.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page49/E3/12.jpg" },
        { url: "img/FriendsPlus/Page49/E3/13.jpg", input: 3 },
        { url: "img/FriendsPlus/Page49/E3/14.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page49/E3/15.jpg", input: 3 },
        { url: "img/FriendsPlus/Page49/E3/16.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page49/E3/17.jpg" },
        { url: "img/FriendsPlus/Page49/E3/18.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page49/E3/19.jpg", input: 4 },
        { url: "img/FriendsPlus/Page49/E3/20.jpg", input: 4 },
        { url: "img/FriendsPlus/Page49/E3/21.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 6",
    id: "WB1-U6-P49-E4",
    audio: "",
    video: "",
    component: D1,
    //typeInput: 'center',
    fontSize: 40,
    inputHeight: "100%",
    isAllowSubmit: true,
    exerciseKey: "img/FriendsPlus/Page49/E4/Key/answerKey.png",
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page49/E4/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page49/E4/2.jpg" },
        { url: "img/FriendsPlus/Page49/E4/3.jpg", input: true, answer: "l" },
        { url: "img/FriendsPlus/Page49/E4/4.jpg" },
        { url: "img/FriendsPlus/Page49/E4/5.jpg", input: true, answer: "m" },
        { url: "img/FriendsPlus/Page49/E4/6.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page49/E4/7.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page49/E4/8.jpg" },
        { url: "img/FriendsPlus/Page49/E4/9.jpg", input: true, answer: "m" },
        { url: "img/FriendsPlus/Page49/E4/10.jpg" },
        { url: "img/FriendsPlus/Page49/E4/11.jpg", input: true, answer: "ll" },
        { url: "img/FriendsPlus/Page49/E4/12.jpg" },
        { url: "img/FriendsPlus/Page49/E4/13.jpg", input: true, answer: "k" },
        { url: "img/FriendsPlus/Page49/E4/14.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page49/E4/15.jpg" },
      ],
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 6",
    id: "WB1-U6-P49-E5",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 1,
    inputHeight: "100%",
    isAllowSubmit: true,
    typeInput: "center",
    // exerciseKey: 'img/FriendsPlus/Page49/E3/Key/1.png',
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page49/E5/3.jpg" }],
      [
        { url: "img/FriendsPlus/Page49/E5/5.jpg" },
        { url: "img/FriendsPlus/Page49/E5/6.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page49/E5/7.jpg" },
        { url: "img/FriendsPlus/Page49/E5/8.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page49/E5/9.jpg" },
        { url: "img/FriendsPlus/Page49/E5/10.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page49/E5/11.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page49/E5/12.jpg" }],
    ],
  },
};

export default json;
