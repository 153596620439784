import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import UnderLineSong from '../../components/ExcerciseTypes/Design/UnderLineSong';


const json = {
  1: { // Exercise num
    unit: 'Starter',
    id: 'WB1-S-P9-E1',
    audio: 'img/FriendsPlus/Page9/Audio/sing.e1.mp3',
    video: '',
    songLyric:
      "<p>Goodbye,Goodbye<p>" +
      "<p>It's time to say goodbye<p>" +
      "<p>To my teacher, to my friends<p>" +
      "<p>Goodbye, goodbye<p>" +
      "<p>Goodbye, goodbye<p>" +
      "<p>It's time to say goodbye<p>" +
      "<p>To my teacher, to my friends.<p>" +
      "<p>Goodbye, goodbye<p>",
    component: UnderLineSong,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page9/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page9/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page9/E1/4.jpg' },
      ],

    ]
  },
  2: { // Exercise num
    unit: 'Starter',
    id: 'WB1-S-P9-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page9/E2/1.jpg' },
      ],
    ]
  },

}

export default json;