import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import UI from '../../components/ExcerciseTypes/UseIt';


const json = {
  1: { // Exercise num
    unit: 'Unit 2',
    id: 'WB1-U2-P17-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page17/2.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 2',
    id: 'WB1-U2-P17-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page17/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E2/2_1.jpg' },
        { url: 'img/FriendsPlus/Page17/E2/2_2.jpg' },
        { url: 'img/FriendsPlus/Page17/E2/2_3.jpg' },
        { url: 'img/FriendsPlus/Page17/E2/2_4.jpg' },
        { url: 'img/FriendsPlus/Page17/E2/2_5.jpg' },
      ],
    ]
  },

}

export default json;