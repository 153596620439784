import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB1-U3-P27-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: false,
    isAllowSubmit: true,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page28/E1/1.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB1-U3-P27-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: false,
    isAllowSubmit: true,
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page28/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page28/E2/2.jpg', audioUrl: "img/FriendsPlus/Page28/Audio/It's a balloon..mp3" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page28/E2/3.jpg', audioUrl: "img/FriendsPlus/Page28/Audio/It's a pencil..mp3" },
      ],

    ]
  },



}

export default json;