import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import UI from '../../components/ExcerciseTypes/UseIt';

const json = {

  1: { // Exercise num
    unit: 'Unit 4',
    id: 'WB1-U4-P30-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page30/2.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'WB1-U4-P30-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page30/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page30/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/bird.mp3' },
        { url: 'img/FriendsPlus/Page30/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/bear.mp3' },
        { url: 'img/FriendsPlus/Page30/E2/6.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/hippo.mp3' },
        { url: 'img/FriendsPlus/Page30/E2/7.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/crocodile.mp3' },
        { url: 'img/FriendsPlus/Page30/E2/8.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/tiger.mp3' },
      ],


    ],
  },


}

export default json;