import L2 from '../../components/ExcerciseTypes/Design/Listen2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {


  1: { // Exercise num
    unit: 'Culture 4',
    id: 'WB1-C-P55-E1',
    audio: '',
    video: '',
    component: D1,

    fontSize: 30,
    exerciseKey: '',
    inputHeight: '',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page55/E1/1.jpg' },
      ],

    ]
  },
  2: { // Exercise num
    unit: 'Culture 4',
    id: 'WB1-C-P55-E2',
    audio: '',
    video: '',
    component: L2,
    titleImage: '',
    question: [
      {
        imgUrl: [
          'img/FriendsPlus/Page55/E2/3.jpg',
          'img/FriendsPlus/Page55/E2/5.jpg',
          'img/FriendsPlus/Page55/E2/6.jpg',
          'img/FriendsPlus/Page55/E2/7.jpg',
          'img/FriendsPlus/Page55/E2/8.jpg',

        ],
        audioUrl: [
          null,
          'img/FriendsPlus/Page55/E2/Audio/1.mp3',
          'img/FriendsPlus/Page55/E2/Audio/2.mp3',
          'img/FriendsPlus/Page55/E2/Audio/3.mp3',
          'img/FriendsPlus/Page55/E2/Audio/4.mp3',
        ],
        isLongAudio: []
      },
    ],
    questionImage: []
  },
}
export default json;