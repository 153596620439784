import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';

const json = {

  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB1-U3-P23-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: false,
    isAllowSubmit: true,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page23/E1/1.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB1-U3-P23-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page23/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page23/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page23/Audio/Is it a plane.mp3' },
        { url: 'img/FriendsPlus/Page23/E2/3.jpg', audioUrl: 'img/FriendsPlus/Page23/Audio/Yes, it is..mp3' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page23/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page23/Audio/Is it a puppet.mp3' },
        { url: 'img/FriendsPlus/Page23/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page23/Audio/Yes, it is..mp3' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page23/E2/6.jpg', audioUrl: 'img/FriendsPlus/Page23/Audio/Is it a robot.mp3' },
        { url: 'img/FriendsPlus/Page23/E2/7.jpg', audioUrl: 'img/FriendsPlus/Page23/Audio/Yes, it is..mp3' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page23/E2/8.jpg', audioUrl: 'img/FriendsPlus/Page23/Audio/Is it a balloon.mp3' },
        { url: 'img/FriendsPlus/Page23/E2/9.jpg', audioUrl: 'img/FriendsPlus/Page23/Audio/Yes, it is..mp3' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page23/E2/10.jpg', audioUrl: 'img/FriendsPlus/Page23/Audio/Is it a teddy bear.mp3' },
        { url: 'img/FriendsPlus/Page23/E2/11.jpg', audioUrl: 'img/FriendsPlus/Page23/Audio/Yes, it is..mp3' },
      ],

    ],
  },


}

export default json;