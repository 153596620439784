

import UI from '../../components/ExcerciseTypes/UseIt';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'WB1-U1-P13-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    titleQuestion: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page13/2.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'WB1-U1-P13-E2',
    audio: '',
    video: '',
    component: D1,
    typeInput: 'center',
    fontSize: 40,
    inputHeight: '100%',
    isAllowSubmit: true,
    exerciseKey: 'img/FriendsPlus/Page13/E2/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page13/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page13/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page13/E2/3.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page13/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page13/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page13/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page13/E2/7.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page13/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page13/E2/9.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'WB1-U1-P13-E3',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 2,
    exerciseKey: 'img/FriendsPlus/Page13/E3/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page13/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page13/E3/2.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page13/E3/3.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page13/E3/4.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page13/E3/5.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page13/E3/6.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page13/E3/7.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page13/E3/8.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page13/E3/9.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page13/E3/10.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page13/E3/11.jpg', input: 10 },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

}

export default json;