import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';


const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'WB1-U1-P11-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: false,
    isAllowSubmit: true,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page11/E1/1.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'WB1-U1-P11-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page11/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page11/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page11/E2/3.jpg', audioUrl: 'img/FriendsPlus/Page11/Audio/What color is it..mp3' },
        { url: 'img/FriendsPlus/Page11/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page11/E2/5.jpg', audioUrl: "img/FriendsPlus/Page11/Audio/It's black..mp3" },
        { url: 'img/FriendsPlus/Page11/E2/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page11/E2/7.jpg' },
        { url: 'img/FriendsPlus/Page11/E2/8.jpg', audioUrl: 'img/FriendsPlus/Page11/Audio/What color is it..mp3' },
        { url: 'img/FriendsPlus/Page11/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page11/E2/10.jpg', audioUrl: "img/FriendsPlus/Page11/Audio/It's green..mp3" },
        { url: 'img/FriendsPlus/Page11/E2/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page11/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page11/E2/13.jpg', audioUrl: 'img/FriendsPlus/Page11/Audio/What color is it..mp3' },
        { url: 'img/FriendsPlus/Page11/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page11/E2/15.jpg', audioUrl: "img/FriendsPlus/Page11/Audio/It's red..mp3" },
        { url: 'img/FriendsPlus/Page11/E2/16.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page11/E2/17.jpg' },

      ],

    ],
  },
}

export default json;