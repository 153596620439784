import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';

const json = {

  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB1-U3-P27-E1',
    audio: 'img/FriendsPlus/Page27/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page27/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page27/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page27/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page27/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/anh1.e1.mp3' },
        { url: 'img/FriendsPlus/Page27/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page27/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/anha.e1.mp3' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page27/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/anh2.e1.mp3' },
        { url: 'img/FriendsPlus/Page27/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page27/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/anhb.e1.mp3' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page27/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/anh3.e1.mp3' },
        { url: 'img/FriendsPlus/Page27/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page27/E1/12.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/anhc.e1.mp3' },
      ],

    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB1-U3-P27-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page27/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page27/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page27/E2/3.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/Is it a teddy bear.mp3' },
        { url: 'img/FriendsPlus/Page27/E2/4.jpg', audioUrl: "img/FriendsPlus/Page27/Audio/No, it isn't..mp3" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page27/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page27/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page27/E2/7.jpg', audioUrl: "img/FriendsPlus/Page27/Audio/Is it a car.mp3" },
        { url: 'img/FriendsPlus/Page27/E2/8.jpg', audioUrl: "img/FriendsPlus/Page27/Audio/No, it isn't..mp3" },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page27/E2/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page27/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page27/E2/11.jpg', audioUrl: "img/FriendsPlus/Page27/Audio/Is it a balloon.mp3" },
        { url: 'img/FriendsPlus/Page27/E2/12.jpg', audioUrl: "img/FriendsPlus/Page27/Audio/Yes, it is..mp3" },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page27/E2/13.jpg' },
      ],

    ],
  },


}

export default json;