import UI from '../../components/ExcerciseTypes/UseIt';
import LT3 from '../../components/ExcerciseTypes/LineTo/LT3';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB1-U3-P24-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page24/2.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB1-U3-P24-E2',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page24/3.jpg' },
      ],
    ]
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'WB1-U3-P24-E3',
    audio: '',
    video: '',
    component: D1,
    //recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page24/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page24/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page24/Audio/girl.mp3' },
        { url: 'img/FriendsPlus/Page24/E3/3.jpg', audioUrl: 'img/FriendsPlus/Page24/Audio/guitar.mp3' },
      ],

    ],
  },

  4: { // Exercise num
    unit: 'Unit 3',
    id: 'WB1-U3-P24-E4',
    audio: '/img/FriendsPlus/Page24/Audio/audio.e4.mp3',
    video: '',
    component: LT3,
    exerciseKey: "/img/FriendsPlus/Page24/E4/Key/answerKey.png",
    titleImage: '',
    numberAnser: 3,
    titleQuestion: [{ num: '4', title: 'Listen and draw a line.' }],
    questionImage: [],
    questions: [
      {
        right: [
          {
            id: 1,
            text: "/img/FriendsPlus/Page24/E4/1.jpg",
          },
          {
            id: 2,
            text: "/img/FriendsPlus/Page24/E4/5.jpg",
          },
          {
            id: 3,
            text: "/img/FriendsPlus/Page24/E4/2.jpg",
          },
          {
            id: 4,
            text: "/img/FriendsPlus/Page24/E4/6.jpg",
          },
          {
            id: 5,
            text: "/img/FriendsPlus/Page24/E4/3.jpg",
          },
          {
            id: 6,
            text: "/img/FriendsPlus/Page24/E4/7.jpg",
          },
        ],
        left: [
          {
            id: 1,
            text: "/img/FriendsPlus/Page24/E4/4.jpg",
          },
        ],
        answer: [
          {
            right: 1,
            left: 1,
          },
          {
            right: 4,
            left: 1,
          },
          {
            right: 6,
            left: 1,
          },
        ],
      },
    ]
  },



}

export default json;