import LT1 from '../../components/ExcerciseTypes/LineTo/LT1';
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';


const json = {
  1: { // Exercise num
    unit: 'Starter',
    id: 'WB1-S-P5-E1',
    audio: 'img/FriendsPlus/Page11/E2/Audio/sing.e2.p11.mp3',
    video: '',
    component: LT1,
    exerciseKey: 'img/FriendsPlus/Page5/E1/Key/answerKey.png',
    recorder: true,
    fromAnchor: '100% 50%',
    toAnchor: '0% 50%',
    titleImage: 'img/FriendsPlus/Page5/E1/1.jpg',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page5/E1/2.jpg', isMatching: true, id: 1 },
        { url: 'img/FriendsPlus/Page5/E1/5.jpg', isMatching: true, id: 2 },
        { url: 'img/FriendsPlus/Page5/E1/8.jpg', isMatching: true, id: 3 },
      ],
      [
        { url: 'img/FriendsPlus/Page5/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page5/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page5/E1/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page5/E1/4.jpg', isMatching: true, id: 4 },
        { url: 'img/FriendsPlus/Page5/E1/7.jpg', isMatching: true, id: 5 },
        { url: 'img/FriendsPlus/Page5/E1/10.jpg', isMatching: true, id: 6 },
      ],
    ],
    answers: ['1-5', '2-6', '3-4'],
  },
  2: { // Exercise num
    unit: 'Starter',
    id: 'WB1-S-P5-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page5/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page5/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/3.jpg', audioUrl: 'img/FriendsPlus/Page5/Audio/1.Listen to your teacher..mp3' },
        { url: 'img/FriendsPlus/Page5/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page5/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page5/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/7.jpg', audioUrl: 'img/FriendsPlus/Page5/Audio/3.Sit down..mp3' },
        { url: 'img/FriendsPlus/Page5/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/9.jpg', audioUrl: 'img/FriendsPlus/Page5/Audio/4.Raise your hand..mp3' },
        { url: 'img/FriendsPlus/Page5/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page5/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page5/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/13.jpg', audioUrl: 'img/FriendsPlus/Page5/Audio/1.Listen to your teacher..mp3' },
        { url: 'img/FriendsPlus/Page5/E2/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page5/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/16.jpg', audioUrl: 'img/FriendsPlus/Page5/Audio/5.Line up..mp3' },
        { url: 'img/FriendsPlus/Page5/E2/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page5/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/19.jpg', audioUrl: 'img/FriendsPlus/Page5/Audio/2.Stand up..mp3' },
        { url: 'img/FriendsPlus/Page5/E2/20.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page5/E2/21.jpg' },
      ],


    ],
  },

}

export default json;